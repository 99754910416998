var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"template"},[(!_vm.hpRecoEnabled)?[_c('Carousel',{staticClass:"template__carousel",attrs:{"slides":_vm.hpHeroBlock ? [_vm.hpHeroBlock] : [],"height":432}}),_vm._v(" "),(_vm.isBlockEnabled('user-favorites'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('user-favorites') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.user-favorites.title'),"items":_vm.userFavorites,"custom-data":{
            showMorePath: _vm.localePath('ListPage-UserFavorites'),
            isFavorite: true
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('recent-consultations'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('recent-consultations') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.recent-consultations.title'),"items":_vm.recentConsultedItems,"custom-data":{
            showMorePath: _vm.localePath('ListPage-RecentlyViewedItems')
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('newsfeed'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('newsfeed') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"title":_vm.$t('homepage.newsfeed.title'),"items":_vm.newsfeedItems}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('crush-items'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('crush-items') }},[_c('div',{staticClass:"container responsive-pad"},[(_vm.crushItems && _vm.crushItems.length)?_c('ProductList',{attrs:{"title":_vm.$t('homepage.crush-items.title'),"items":_vm.crushItems}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('latest-published'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('latest-published') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"title":_vm.$t('homepage.latest-published.title'),"items":_vm.latestPublished}}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"padding":"20px 0"}},[_c('Button',{attrs:{"size":"large","to":_vm.localePath({ name: 'ListPage-LatestPublished' }),"outline":""}},[_vm._v(_vm._s(_vm.$t('common.see-more')))])],1)],1)]):_vm._e()]:[(!_vm.$auth.loggedIn)?_c('Carousel',{staticClass:"template__carousel",attrs:{"slides":_vm.hpHeroBlock ? [_vm.hpHeroBlock] : [],"height":432}}):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('for-you'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('for-you') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.for-you.title'),"items":_vm.forYouItems,"custom-data":{
            showMorePath: _vm.localePath('ListPage-RecoForYou')
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('recent-consultations'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('recent-consultations') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.recent-consultations.title'),"items":_vm.recentConsultedItems,"custom-data":{
            showMorePath: _vm.localePath('ListPage-RecentlyViewedItems')
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('user-favorites'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('user-favorites') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.user-favorites.title'),"items":_vm.userFavorites,"custom-data":{
            showMorePath: _vm.localePath('ListPage-UserFavorites'),
            isFavorite: true
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('brand-1-for-you'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('brand-1-for-you') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.brand-for-you.title', {
              brand: _vm.brand1ForYouItems.brand.name
            }),"items":_vm.brand1ForYouItems.items.slice(0, 5),"custom-data":{
            showMorePath: _vm.localePath({
              name: 'ListPage-RecoBrandForYou',
              params: { slug: _vm.brand1ForYouItems.brand.slug }
            }),
            subTitle: _vm.$t('homepage.brand-for-you.sub-title')
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('brand-2-for-you'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('brand-2-for-you') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.brand-for-you.title', {
              brand: _vm.brand2ForYouItems.brand.name
            }),"items":_vm.brand2ForYouItems.items.slice(0, 5),"custom-data":{
            showMorePath: _vm.localePath({
              name: 'ListPage-RecoBrandForYou',
              params: { slug: _vm.brand2ForYouItems.brand.slug }
            }),
            subTitle: _vm.$t('homepage.brand-for-you.sub-title')
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('popular-for-you'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('popular-for-you') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"template":"show-more","title":_vm.$t('homepage.popular-for-you.title'),"items":_vm.popularForYouItems,"custom-data":{
            showMorePath: _vm.localePath('ListPage-RecoPopularForYou')
          }}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('newsfeed'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('newsfeed') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"title":_vm.$t('homepage.newsfeed.title'),"items":_vm.newsfeedItems}}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"padding":"20px 0"}},[_c('Button',{attrs:{"size":"large","to":_vm.localePath({ name: 'ListPage-RecoNewsFeed' }),"outline":""}},[_vm._v("\n            "+_vm._s(_vm.$t('common.see-more'))+"\n          ")])],1)],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('last-search'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('last-search') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"title":_vm.$t('homepage.last-search.title'),"items":_vm.lastSearchItems}})],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('popular'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('popular') }},[_c('div',{staticClass:"container responsive-pad"},[(_vm.popularItems && _vm.popularItems.length)?_c('ProductList',{attrs:{"title":_vm.$t('homepage.popular.title'),"items":_vm.popularItems}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.isBlockEnabled('latest-published'))?_c('div',{class:{ 'porcelain-bg': _vm.isBgPorcelain('latest-published') }},[_c('div',{staticClass:"container responsive-pad"},[_c('ProductList',{attrs:{"title":_vm.$t('homepage.latest-published.title'),"items":_vm.latestPublished}}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"padding":"20px 0"}},[_c('Button',{attrs:{"size":"large","to":_vm.localePath({ name: 'ListPage-LatestPublished' }),"outline":""}},[_vm._v(_vm._s(_vm.$t('common.see-more')))])],1)],1)]):_vm._e()],_vm._v(" "),_c('FreeFeesModal',{ref:"freeFeesModal"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }