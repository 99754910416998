<template>
  <component :is="listTemplate" v-bind="$props" v-on="$listeners" />
</template>

<script>
import { pascalCase } from '@/misc/string';

export default {
  name: 'ProductList',
  components: {
    Homepage: () => import('./templates/Homepage'),
    ListPage: () => import('./templates/ListPage'),
    Dressing: () => import('./templates/Dressing'),
    ShowMore: () => import('./templates/ShowMore')
  },
  props: {
    title: { type: String },
    items: { type: Array },
    template: {
      type: String,
      default: 'homepage',
      validator(val) {
        return ['homepage', 'list-page', 'dressing', 'show-more'].includes(val);
      }
    },
    page: { type: Number },
    perPage: { type: Number },
    pageCount: { type: Number },
    searchable: { type: Boolean, default: false },
    searchQuery: { type: String, default: null },
    queryID: { type: String, default: null },
    loading: { type: Boolean, default: false },
    noSaveSearch: { type: Boolean, default: false },
    query: { type: String, default: null },
    filters: {
      type: Object,
      default: () => null
    },
    selectedFilters: {
      type: Object,
      default: () => null
    },
    activeFilters: {
      type: Array,
      default: () => null
    },
    hideFilters: {
      type: Boolean,
      default: false
    },
    selectedSort: {
      type: String,
      default: null
    },
    crumbs: {
      type: Array,
      default: () => null
    },
    customData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    listTemplate() {
      return pascalCase(this.template);
    }
  }
};
</script>
