<template>
  <div class="template">
    <template v-if="!hpRecoEnabled">
      <Carousel
        class="template__carousel"
        :slides="hpHeroBlock ? [hpHeroBlock] : []"
        :height="432"
      />
      <div
        v-if="isBlockEnabled('user-favorites')"
        :class="{ 'porcelain-bg': isBgPorcelain('user-favorites') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="$t('homepage.user-favorites.title')"
            :items="userFavorites"
            :custom-data="{
              showMorePath: localePath('ListPage-UserFavorites'),
              isFavorite: true
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('recent-consultations')"
        :class="{ 'porcelain-bg': isBgPorcelain('recent-consultations') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="$t('homepage.recent-consultations.title')"
            :items="recentConsultedItems"
            :custom-data="{
              showMorePath: localePath('ListPage-RecentlyViewedItems')
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('newsfeed')"
        :class="{ 'porcelain-bg': isBgPorcelain('newsfeed') }"
      >
        <div class="container responsive-pad">
          <ProductList
            :title="$t('homepage.newsfeed.title')"
            :items="newsfeedItems"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('crush-items')"
        :class="{ 'porcelain-bg': isBgPorcelain('crush-items') }"
      >
        <div class="container responsive-pad">
          <ProductList
            v-if="crushItems && crushItems.length"
            :title="$t('homepage.crush-items.title')"
            :items="crushItems"
          />
        </div>
      </div>
      <!-- <div class="container responsive-pad">
      <ProductList
        v-if="mostLiked && mostLiked.length"
        :title="$t('homepage.most-liked.title')"
        :items="mostLiked"
      />
    </div> -->
      <div
        v-if="isBlockEnabled('latest-published')"
        :class="{ 'porcelain-bg': isBgPorcelain('latest-published') }"
      >
        <div class="container responsive-pad">
          <ProductList
            :title="$t('homepage.latest-published.title')"
            :items="latestPublished"
          />
          <div class="d-flex justify-content-center" style="padding: 20px 0">
            <Button
              size="large"
              :to="localePath({ name: 'ListPage-LatestPublished' })"
              outline
              >{{ $t('common.see-more') }}</Button
            >
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <Carousel
        v-if="!$auth.loggedIn"
        class="template__carousel"
        :slides="hpHeroBlock ? [hpHeroBlock] : []"
        :height="432"
      />
      <div
        v-if="isBlockEnabled('for-you')"
        :class="{ 'porcelain-bg': isBgPorcelain('for-you') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="$t('homepage.for-you.title')"
            :items="forYouItems"
            :custom-data="{
              showMorePath: localePath('ListPage-RecoForYou')
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('recent-consultations')"
        :class="{ 'porcelain-bg': isBgPorcelain('recent-consultations') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="$t('homepage.recent-consultations.title')"
            :items="recentConsultedItems"
            :custom-data="{
              showMorePath: localePath('ListPage-RecentlyViewedItems')
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('user-favorites')"
        :class="{ 'porcelain-bg': isBgPorcelain('user-favorites') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="$t('homepage.user-favorites.title')"
            :items="userFavorites"
            :custom-data="{
              showMorePath: localePath('ListPage-UserFavorites'),
              isFavorite: true
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('brand-1-for-you')"
        :class="{ 'porcelain-bg': isBgPorcelain('brand-1-for-you') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="
              $t('homepage.brand-for-you.title', {
                brand: brand1ForYouItems.brand.name
              })
            "
            :items="brand1ForYouItems.items.slice(0, 5)"
            :custom-data="{
              showMorePath: localePath({
                name: 'ListPage-RecoBrandForYou',
                params: { slug: brand1ForYouItems.brand.slug }
              }),
              subTitle: $t('homepage.brand-for-you.sub-title')
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('brand-2-for-you')"
        :class="{ 'porcelain-bg': isBgPorcelain('brand-2-for-you') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="
              $t('homepage.brand-for-you.title', {
                brand: brand2ForYouItems.brand.name
              })
            "
            :items="brand2ForYouItems.items.slice(0, 5)"
            :custom-data="{
              showMorePath: localePath({
                name: 'ListPage-RecoBrandForYou',
                params: { slug: brand2ForYouItems.brand.slug }
              }),
              subTitle: $t('homepage.brand-for-you.sub-title')
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('popular-for-you')"
        :class="{ 'porcelain-bg': isBgPorcelain('popular-for-you') }"
      >
        <div class="container responsive-pad">
          <ProductList
            template="show-more"
            :title="$t('homepage.popular-for-you.title')"
            :items="popularForYouItems"
            :custom-data="{
              showMorePath: localePath('ListPage-RecoPopularForYou')
            }"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('newsfeed')"
        :class="{ 'porcelain-bg': isBgPorcelain('newsfeed') }"
      >
        <div class="container responsive-pad">
          <ProductList
            :title="$t('homepage.newsfeed.title')"
            :items="newsfeedItems"
          />
          <div class="d-flex justify-content-center" style="padding: 20px 0">
            <Button
              size="large"
              :to="localePath({ name: 'ListPage-RecoNewsFeed' })"
              outline
            >
              {{ $t('common.see-more') }}
            </Button>
          </div>
        </div>
      </div>
      <div
        v-if="isBlockEnabled('last-search')"
        :class="{ 'porcelain-bg': isBgPorcelain('last-search') }"
      >
        <div class="container responsive-pad">
          <ProductList
            :title="$t('homepage.last-search.title')"
            :items="lastSearchItems"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('popular')"
        :class="{ 'porcelain-bg': isBgPorcelain('popular') }"
      >
        <div class="container responsive-pad">
          <ProductList
            v-if="popularItems && popularItems.length"
            :title="$t('homepage.popular.title')"
            :items="popularItems"
          />
        </div>
      </div>
      <div
        v-if="isBlockEnabled('latest-published')"
        :class="{ 'porcelain-bg': isBgPorcelain('latest-published') }"
      >
        <div class="container responsive-pad">
          <ProductList
            :title="$t('homepage.latest-published.title')"
            :items="latestPublished"
          />
          <div class="d-flex justify-content-center" style="padding: 20px 0">
            <Button
              size="large"
              :to="localePath({ name: 'ListPage-LatestPublished' })"
              outline
              >{{ $t('common.see-more') }}</Button
            >
          </div>
        </div>
      </div>
    </template>
    <FreeFeesModal ref="freeFeesModal" />
  </div>
</template>

<script>
import ProductList from '@/components/ProductList/ProductList';
import Button from '@/components/Button/Button';
import Carousel from '@/components/Carousel/Carousel';
import FreeFeesModal from '@/components/Modal/FreeFeesModal';
import { getBlock, getSelection, getSelectionItems } from '@/api/merch';
import { getLatestPublished, getPopularItems, getMostLiked } from '@/api/item';
import {
  getRecentConsultedItems,
  getLikedItems,
  getLastSearchItems,
  getForYouItems,
  getBrandsForYouItems,
  getPopularForYouItems,
  getNewsfeedItems
} from '@/api/user';
import { captureErrorMixin } from '@/mixins/captureError';
import isNil from 'lodash.isnil';

export default {
  name: 'All',
  components: {
    ProductList,
    Carousel,
    Button,
    FreeFeesModal
  },
  mixins: [captureErrorMixin],
  head() {
    return {
      title: this.$t('seo.homepage.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('seo.homepage.description')
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.$t('seo.homepage.title')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.$t('seo.homepage.description')
        }
      ]
    };
  },
  data: () => ({
    lastSearchBannerEnabled: false,
    hpRecoEnabled: false,
    hpHeroBlock: null,
    popularItems: null,
    crushItems: null,
    mostLiked: null,
    latestPublished: null,
    recentConsultations: null,
    userFavorites: null,
    lastSearchItems: null,
    forYouItems: null,
    brand1ForYouItems: null,
    brand2ForYouItems: null,
    popularForYouItems: null,
    newsfeedItems: null
  }),
  async fetch() {
    this.lastSearchBannerEnabled = await this.$feature.isActive(
      'last-search-banner'
    );
    this.hpRecoEnabled = await this.$feature.isActive('hp-reco');
    await this.reload();
  },
  computed: {
    recentConsultedItems() {
      if (isNil(this.recentConsultations)) return null;
      return this.recentConsultations.map((r) => r.item);
    },
    blocks() {
      return [
        {
          name: 'user-favorites',
          enabled:
            this.$auth.loggedIn &&
            this.userFavorites &&
            this.userFavorites.length
        },
        {
          name: 'recent-consultations',
          enabled:
            this.$auth.loggedIn &&
            this.recentConsultedItems &&
            this.recentConsultedItems.length
        },
        {
          name: 'for-you',
          enabled:
            this.$auth.loggedIn && this.forYouItems && this.forYouItems.length
        },
        {
          name: 'brand-1-for-you',
          enabled:
            this.$auth.loggedIn &&
            this.brand1ForYouItems &&
            this.brand1ForYouItems.items &&
            this.brand1ForYouItems.items.length
        },
        {
          name: 'brand-2-for-you',
          enabled:
            this.$auth.loggedIn &&
            this.brand2ForYouItems &&
            this.brand2ForYouItems.items &&
            this.brand2ForYouItems.items.length
        },
        {
          name: 'popular-for-you',
          enabled:
            this.$auth.loggedIn &&
            this.popularForYouItems &&
            this.popularForYouItems.length
        },
        {
          name: 'newsfeed',
          enabled:
            this.$auth.loggedIn &&
            this.newsfeedItems &&
            this.newsfeedItems.length
        },
        // {
        //   name: 'last-search',
        //   enabled:
        //     this.$auth.loggedIn &&
        //     this.lastSearchItems &&
        //     this.lastSearchItems.length
        // },
        {
          name: 'popular',
          enabled:
            !this.$auth.loggedIn &&
            this.popularItems &&
            this.popularItems.length
        },
        {
          name: 'crush-items',
          enabled: this.crushItems && this.crushItems.length
        },
        {
          name: 'latest-published',
          enabled:
            (!this.$auth.loggedIn ||
              (this.newsfeedItems && !this.newsfeedItems.length)) &&
            this.latestPublished &&
            this.latestPublished.length
        }
      ];
    }
  },
  mounted() {
    this.$bus.$on('sign-in:success', this.reload);
    const {
      d: encodedData,
      warn: warningMsg,
      error: errorMsg
    } = this.$route.query;
    if (!this.$auth.loggedIn && encodedData) {
      try {
        const email = decodeURIComponent(window.atob(encodedData));
        setTimeout(() => this.$bus.$emit('auth:sign-up:finalize', email), 500);
      } catch {}
    } else if (warningMsg) {
      this.$toast.warning(this.$t(window.atob(warningMsg)));
    } else if (errorMsg) {
      this.$toast.error(this.$t(window.atob(errorMsg)));
    }
    // Free fees modal
    const now = new Date();
    const lastDate = new Date('2023-09-30 23:59:00+0200');
    if (now < lastDate) {
      setTimeout(() => {
        let shouldDisplay = true;
        const lastClosed = window.localStorage.getItem(
          'free-fees:reminder:last-closed'
        );
        if (!isNil(lastClosed)) {
          const threshold = 5 * 24 * 60 * 60 * 1000; // 5 days (in ms)
          Date.now() - lastClosed < threshold && (shouldDisplay = false);
        }
        shouldDisplay && this.$refs.freeFeesModal.show();
      }, 750);
    }
  },
  methods: {
    async reload() {
      await this.try(async () => {
        await Promise.all([
          this.getMerchBlock(),
          this.getPopularItems(),
          // this.getCrushItems(),
          this.get100LatestPublished(),
          this.getRecentConsultedItems(),
          this.get5FavoriteItems(),
          // this.getLastSearchItems(),
          this.getForYouItems(),
          this.getBrandsForYouItems(),
          this.getPopularForYouItems(),
          this.getNewsfeedItems()
        ]);
      });
    },
    async getMerchBlock() {
      await this.try(async () => {
        this.hpHeroBlock = await this.$api.doRequest(getBlock, 'hp-hero');
      });
    },
    async getPopularItems() {
      if (this.$auth.loggedIn) return;
      await this.try(async () => {
        const data = await this.$api.doRequest(getPopularItems, null, 25, true);
        const _items = data?.['hydra:member'] || [];
        _items.sort(() => 0.5 - Math.random()); // Shuffle
        this.popularItems = _items;
      });
    },
    async getCrushItems() {
      if (this.$auth.loggedIn) return;
      await this.try(async () => {
        const crushItemsSelection = await this.$api.doRequest(
          getSelection,
          'crush-items'
        );
        if (crushItemsSelection) {
          this.crushItems = await this.$api.doRequest(
            getSelectionItems,
            crushItemsSelection.id,
            25
          );
        }
      });
    },
    async getRecentConsultedItems() {
      if (!this.$auth.loggedIn) return;
      await this.try(async () => {
        const data = await this.$api.doRequest(
          getRecentConsultedItems,
          this.$auth.user.id,
          15
        );
        const _items = data?.['hydra:member'] || null;
        !isNil(_items) && _items.sort(() => 0.5 - Math.random()); // Shuffle
        this.recentConsultations = _items.slice(0, 5);
      });
    },
    async get100LatestPublished() {
      const { $api } = this;
      const promises = [...Array(4).keys()].map((index) => {
        const page = index + 1;
        return $api.doRequest(getLatestPublished, page, 25).catch(() => null);
      });
      const latestPublished = await Promise.all(promises);
      const mergedItems = latestPublished.reduce((acc, data) => {
        !isNil(data?.['hydra:member']) && acc.push(...data['hydra:member']);
        return acc;
      }, []);
      this.latestPublished = mergedItems.slice(0, 100);
    },
    async get5FavoriteItems() {
      if (!this.$auth.loggedIn) return;
      let _favoriteItems = [];
      await this.try(async () => {
        const data = await this.$api.doRequest(
          getLikedItems,
          this.$auth.user.id,
          15,
          1
        );
        _favoriteItems = (data['hydra:member'] || [])
          .map((ufi) => ufi.item)
          .filter((i) => i.publicationState === 'published');
      });
      _favoriteItems.sort(() => 0.5 - Math.random()); // Shuffle
      this.userFavorites = _favoriteItems.slice(0, 5);
    },
    async getLastSearchItems() {
      if (!this.$auth.loggedIn) return;
      if (!this.lastSearchBannerEnabled) return;
      await this.try(async () => {
        this.lastSearchItems = await this.$api.doRequest(
          getLastSearchItems,
          this.$auth.user.id
        );
      });
    },
    async getForYouItems() {
      if (!this.$auth.loggedIn) return;
      await this.try(async () => {
        const data = await this.$api.doRequest(
          getForYouItems,
          this.$auth.user.id,
          1,
          15
        );
        const _items = data?.['hydra:member'] || [];
        _items.sort(() => 0.5 - Math.random()); // Shuffle
        this.forYouItems = _items.slice(0, 5);
      });
    },
    async getBrandsForYouItems() {
      if (!this.$auth.loggedIn) return;
      await this.try(async () => {
        const brandItems = await this.$api.doRequest(
          getBrandsForYouItems,
          this.$auth.user.id,
          15
        );
        const _brand1Items = brandItems[0] || [];
        _brand1Items.items?.sort(() => 0.5 - Math.random()); // Shuffle
        this.brand1ForYouItems = _brand1Items;
        const _brand2Items = brandItems[1] || [];
        _brand2Items.items?.sort(() => 0.5 - Math.random()); // Shuffle
        this.brand2ForYouItems = _brand2Items;
      });
    },
    async getPopularForYouItems() {
      if (!this.$auth.loggedIn) return;
      await this.try(async () => {
        const data = await this.$api.doRequest(
          getPopularForYouItems,
          this.$auth.user.id,
          1,
          5,
          true
        );
        const _items = data?.['hydra:member'] || [];
        _items.sort(() => 0.5 - Math.random()); // Shuffle
        this.popularForYouItems = _items;
      });
    },
    async getNewsfeedItems() {
      if (!this.$auth.loggedIn) return;
      await this.try(async () => {
        const data = await this.$api.doRequest(
          getNewsfeedItems,
          this.$auth.user.id,
          1,
          50
        );
        this.newsfeedItems = data['hydra:member'] || [];
      });
    },
    isBlockEnabled(name) {
      return this.blocks.find((b) => b.name === name)?.enabled || false;
    },
    isBgPorcelain(name) {
      const enabledBlocks = this.blocks.filter((b) => b.enabled);
      const index = enabledBlocks.findIndex((b) => b.name === name);
      return index % 2 === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.template {
  position: relative;
  min-height: 100%;
  background: $white;

  &__loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $white;
  }

  .porcelain-bg {
    background-color: $porcelain;
  }

  &__carousel + div {
    /deep/ .product-list__title {
      margin-top: 6px;
    }
  }
}
</style>
